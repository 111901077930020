import { RouteConfig } from "vue-router";
import { guards } from "@/router/guards";
import store from "@/store";

export const sco2pesRoutes: Array<RouteConfig> = [
  {
    path: "",
    component: () => import("@/views/sco2pes/welcome.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `info`,
    component: () => import("@/views/sco2pes/info/index.vue"),
    beforeEnter: guards.customerWithoutScopesLicenseGuard,
  },
  {
    path: `bookings/financial-bookings`,
    component: () => import("@/views/sco2pes/bookings/financial/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `bookings/unit-bookings`,
    component: () => import("@/views/sco2pes/bookings/unit/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `finalised-reports`,
    component: () => import("@/views/sco2pes/finalised-reports/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `dashboard`,
    component: () => import("@/views/sco2pes/dashboard/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: "company-group",
    component: () => import("@/views/sco2pes/company-group/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `suppliers`,
    component: () => import("@/views/sco2pes/suppliers/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `financial-models`,
    component: () => import("@/views/sco2pes/financial-models/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `co2-database`,
    component: () => import("@/views/sco2pes/co2-database/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    name: "co2-type-view",
    path: `co2-database/:id`,
    component: () => import("@/views/sco2pes/co2-database/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `commuter-surveys`,
    component: () => import("@/views/sco2pes/commuter-surveys/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `commuter-surveys/:id`,
    component: () => import("@/views/sco2pes/commuter-surveys/details/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `company-codes`,
    component: () => import("@/views/sco2pes/company-codes/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  {
    path: `checklist`,
    component: () => import("@gc/legacy-components/src/checklist/index.vue"),
    beforeEnter: guards.customerWithScopesLicenseGuard,
  },
  ...(store?.state.currentCompany().featureFlags.integration
    ? [
        {
          path: `integration/co2-type-tags`,
          component: () => import("@/views/sco2pes/integration/co2-type-tags/index.vue"),
          beforeEnter: guards.customerWithScopesLicenseGuard,
        },
        {
          path: `integration/configuration`,
          component: () => import("@/views/sco2pes/integration/configuration/index.vue"),
          beforeEnter: guards.customerWithScopesLicenseGuard,
        },
      ]
    : []),
  ...(store?.state.currentCompany().featureFlags.showPendingBookingPages
    ? [
        {
          path: `bookings/pending-unit-bookings`,
          component: () => import("@/views/sco2pes/bookings/pending-unit/index.vue"),
          beforeEnter: guards.customerWithScopesLicenseGuard,
        },
        {
          path: `bookings/pending-financial-bookings`,
          component: () => import("@/views/sco2pes/bookings/pending-financial/index.vue"),
          beforeEnter: guards.customerWithScopesLicenseGuard,
        },
      ]
    : []),
];
